import { createContext } from "react";
import useFetch from './useFetch'

export const DataHousing = createContext()


function DataProvider({ children }) {

  const { data, isLoading, error } = useFetch('https://p6appimmodeployed.pages.dev/housing.json')

  return (
    <DataHousing.Provider value={{ data, isLoading, error }}>
      {children}
    </DataHousing.Provider>
  )
}

export default DataProvider;